const baseUrl = 'https://assets.nl-kompetenzzentrum.de'
export default {
  slides: [
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6381.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6381.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6381.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6381.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6381.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6351.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6351.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6351.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6351.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6351.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6398.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6398.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6398.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6398.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6398.jpg`
      }
    }
  ],
  texts: [
    {
      href: `${baseUrl}/content/gruender/p1.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog-Niederlassungsberatung.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Einstieg Niederlassung',
          slideLink: 1
        },
        {
          label: 'Neugründung Niederlassung',
          slideLink: 2
        }
      ]
    },
    {
      href: `${baseUrl}/content/gruender/p2.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog-Niederlassungsberatung.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Übernahme Niederlassung',
          slideLink: 0
        },
        {
          label: 'Neugründung Niederlassung',
          slideLink: 2
        }
      ]
    },
    {
      href: `${baseUrl}/content/gruender/p3.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog-Niederlassungsberatung.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Übernahme Niederlassung',
          slideLink: 0
        },
        {
          label: 'Einstieg Niederlassung',
          slideLink: 1
        }
      ]
    }
  ]
}
